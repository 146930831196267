import "../styles/MainSlider.scss";
import { useState, useEffect, useRef } from "react";
import leftBtn from "../icons/left-btn.svg";
import rightBtn from "../icons/right-btn.svg";
import Modal from "./Modal";
import services from "../data/services";

export default function MainSlider() {
  const [currIndex, setCurrIndex] = useState(0);
  const [modal, setModal] = useState(false);
  const interval = useRef(null);

  useEffect(() => {
    interval.current = setInterval(() => {
      nextSlide();
    }, 5000);

    return () => clearInterval(interval.current);
  }, []);

  function prevSlide() {
    setCurrIndex((index) => (index - 1 + services.length) % services.length);
    clearInterval(interval.current);
  }

  function nextSlide() {
    setCurrIndex((index) => (index + 1) % services.length);
    clearInterval(interval.current);
  }

  function toggleModal() {
    setModal((state) => (state = !state));
  }

  function learnMoreBtn() {
    clearInterval(interval.current);
    toggleModal();
    let btns = Array.from(document.querySelectorAll(".slider__btn"));
    btns.forEach((btn) => {
      btn.style.display = "none";
    });
  }

  function showNextBtns() {
    toggleModal();
    let btns = Array.from(document.querySelectorAll(".slider__btn"));
    btns.forEach((btn) => {
      btn.style.display = "";
    });
  }

  return (
    <div className="slider__container">
      <button className="slider__btn-prev slider__btn" onClick={prevSlide}>
        <img src={leftBtn} alt="left-btn"></img>
      </button>
      {services.map((img, index) => (
        <div
          key={index}
          className={`slide ${index === currIndex ? "active" : ""}`}
          style={{
            backgroundImage: `url(${img.url})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div>
            {modal ? (
              <Modal
                modalObj={services[index].modal}
                onShowBtn={showNextBtns}
              />
            ) : null}
          </div>
          <div className="headers__container">
            <h1
              className={`slider__header ${
                index === currIndex ? "active" : ""
              }`}
            >
              {img.title}
            </h1>
            <p
              className={`slider__description ${
                index === currIndex && "active"
              }`}
            >
              {img.description}
            </p>
            <button
              onClick={learnMoreBtn}
              className={`learn-more__btn ${
                index === currIndex ? "active" : ""
              }`}
            >
              Learn More
            </button>
          </div>
        </div>
      ))}
      <button className="slider__btn-next slider__btn" onClick={nextSlide}>
        <img src={rightBtn} alt="right-btn"></img>
      </button>
    </div>
  );
}
